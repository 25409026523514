import { motion } from 'framer-motion'

import { useTitle } from '@/hooks/useTitle'
import { animationOne, transition } from '@/utils/animations/index'

// import
import HomeHeader from '@pages/Home/HomeHeader'
import TrendyVideo from '@pages/Home/TrendyVideo'
import SocialNetwork from '@components/SocialNetwork/index'
import BannerDiscord from '@pages/Home/BannerDiscord'
import { Adsense } from '@ctrl/react-adsense'

const Home = () => {
  useTitle('Accueil')
  return (
    <motion.div
      key="home"
      initial="out"
      animate="in"
      exit="exit"
      variants={animationOne}
      transition={transition}
      className="text"
    >
      <div className="flex items-center text-center flex-col">
        <HomeHeader />
        <div className="flex items-center flex-col rounded-md border border-solid border-gold bg-bgCard  lg:mb-4em p-1em lg:w-auto w-95%">
          <p className="block p-y-.3em">
            Le site parfait pour <strong className="gold">apprendre</strong> et{' '}
            <strong className="gold">maîtriser</strong> les trollpicks !
          </p>
          <Adsense
            client="ca-pub-9607527913869623"
            slot="5886482035"
            style={{ display: 'inline-block', width: 300, height: 600 }}
            adTest="on"
          />
          <p className="block p-y-.3em">
            &quot;C’est en achetant n’importe quoi qu’on OneShot n’importe
            qui&quot; <span className="gold">-Slipix</span>
          </p>
        </div>
        <TrendyVideo />
        <SocialNetwork />
        <BannerDiscord />
      </div>
    </motion.div>
  )
}

export default Home
